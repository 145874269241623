// 角色控制器
import { useRuntimeConfig } from '#app'

const { useCustomFetch } = useFetchBase()

const base = '/api/auth'
const modules = '/roles'
const path = base + modules

// request(REQ) Api  --------------------------------
const getRolesREQ = path + '/list' // 獲取角色列表
const getRolesDetailREQ = path + '/list/detail' // 獲取角色列表信息 (包含座席)
const addRoleREQ = path + '/add' // 添加角色
const editRoleREQ = path + '/update' // 修改角色
const deleteRoleREQ = path + '/del' // 刪除角色

const getPermissionsREQ = path + '/permissions/refer' // 獲取要添加角色的全權限與套用角色權限ids
const editPermissionsREQ = path + '/permissions/list' // 獲取要修改角色的全權限與權限ids(編輯權限)
const editRolePermissionsREQ = path + '/permissions/update' // 修改角色權限清單(編輯角色權限)

const getUserPermissions = path + '/userPermissions' // 取得當前用戶的角色權限

// request(REQ) Function  --------------------------------

/**
 * 獲取角色列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getRolesFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getRolesREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取角色列表信息 (包含座席)
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getRolesDetailFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getRolesDetailREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 添加角色
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const addRoleFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addRoleREQ, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 修改角色
 * @param {Object} params 查詢參數
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const editRoleFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editRoleREQ, params), {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除角色
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const deleteRoleFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(deleteRoleREQ, params), {
    method: 'DELETE',
    onResponse: callback
  })
}

/**
 * 獲取要添加角色的全權限與套用角色權限ids
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const getPermissionsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(getPermissionsREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取要修改角色的全權限與權限ids(編輯權限)
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns
 */
export const editPermissionsFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editPermissionsREQ, params), {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 修改角色權限清單(編輯角色權限)
 * @param {Object} params 查詢參數
 * @param {Object} formData 提交表單
 * @param {Object} callback 回調函數
 * @returns
 */
export const editRolePermissionsFn = (params, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + combineUrlWithParams(editRolePermissionsREQ, params), {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 取得當前用戶的角色權限
 * @param {Object} callback 回調函數
 * @returns
 */
export const getUserPermissionsFn = (callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + getUserPermissions, {
    method: 'GET',
    onResponse: callback
  })
}
