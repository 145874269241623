import { default as _91channelUrl_93QXcxzim4KbMeta } from "C:/develop/workspace/aican_v2_ui/pages/chat/[channelUrl].vue?macro=true";
import { default as forgetPwd8qXMePJph3Meta } from "C:/develop/workspace/aican_v2_ui/pages/forgetPwd.vue?macro=true";
import { default as conversationMZZaPngIUBMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/conversation.vue?macro=true";
import { default as customerCenterbKAhlVieHdMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/customerCenter.vue?macro=true";
import { default as _403m3qOfsvhuqMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/errorPage/403.vue?macro=true";
import { default as _404I77BEvb8V2Meta } from "C:/develop/workspace/aican_v2_ui/pages/index/errorPage/404.vue?macro=true";
import { default as feedbackcscxJJTwtiMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/feedback.vue?macro=true";
import { default as historyN2wHI1j50PMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/history.vue?macro=true";
import { default as homeeNvDUvt6suMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/home.vue?macro=true";
import { default as profilecGaZf1411mMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/profile.vue?macro=true";
import { default as assignSjro3xUqyzMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/assign.vue?macro=true";
import { default as basicvYdQvMRViXMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/basic.vue?macro=true";
import { default as blacklisthdG3UHxpjIMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/blacklist.vue?macro=true";
import { default as conversationTagQ1CBcS01yAMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/conversationTag.vue?macro=true";
import { default as customerTagmA1nvn7o77Meta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/customerTag.vue?macro=true";
import { default as queueltOyyundh1Meta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/queue.vue?macro=true";
import { default as quickReplyu4zwm5EYqGMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/quickReply.vue?macro=true";
import { default as roles84da5NiSS7Meta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/roles.vue?macro=true";
import { default as satisfactionVGGI0B2ZYiMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/satisfaction.vue?macro=true";
import { default as sensitivegnklZAz8ziMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/sensitive.vue?macro=true";
import { default as teamGwsWFfWzaXMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/team.vue?macro=true";
import { default as trustedDomain0TM51LJkSlMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/trustedDomain.vue?macro=true";
import { default as setup2iZshKHPBeMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/webAccess/setup.vue?macro=true";
import { default as webmXcwSAndb9Meta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/webAccess/web.vue?macro=true";
import { default as webAccessKvVmWmGBnLMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup/webAccess.vue?macro=true";
import { default as setupG5cF1dYuouMeta } from "C:/develop/workspace/aican_v2_ui/pages/index/setup.vue?macro=true";
import { default as indexpNJODP33YvMeta } from "C:/develop/workspace/aican_v2_ui/pages/index.vue?macro=true";
import { default as inviteUserVerifyiqirooxJP2Meta } from "C:/develop/workspace/aican_v2_ui/pages/inviteUserVerify.vue?macro=true";
import { default as loginbUA1wJ5aBWMeta } from "C:/develop/workspace/aican_v2_ui/pages/login.vue?macro=true";
import { default as registermTzyMzL8MZMeta } from "C:/develop/workspace/aican_v2_ui/pages/register.vue?macro=true";
import { default as registerVerifyA942tluL9pMeta } from "C:/develop/workspace/aican_v2_ui/pages/registerVerify.vue?macro=true";
import { default as resetPwde9sDNq7BJfMeta } from "C:/develop/workspace/aican_v2_ui/pages/resetPwd.vue?macro=true";
import { default as verifyQK6svCR667Meta } from "C:/develop/workspace/aican_v2_ui/pages/verify.vue?macro=true";
export default [
  {
    name: _91channelUrl_93QXcxzim4KbMeta?.name ?? "chat-channelUrl",
    path: _91channelUrl_93QXcxzim4KbMeta?.path ?? "/chat/:channelUrl()",
    meta: _91channelUrl_93QXcxzim4KbMeta || {},
    alias: _91channelUrl_93QXcxzim4KbMeta?.alias || [],
    redirect: _91channelUrl_93QXcxzim4KbMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/chat/[channelUrl].vue").then(m => m.default || m)
  },
  {
    name: forgetPwd8qXMePJph3Meta?.name ?? "forgetPwd",
    path: forgetPwd8qXMePJph3Meta?.path ?? "/forgetPwd",
    meta: forgetPwd8qXMePJph3Meta || {},
    alias: forgetPwd8qXMePJph3Meta?.alias || [],
    redirect: forgetPwd8qXMePJph3Meta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/forgetPwd.vue").then(m => m.default || m)
  },
  {
    name: indexpNJODP33YvMeta?.name ?? "index",
    path: indexpNJODP33YvMeta?.path ?? "/",
    children: [
  {
    name: conversationMZZaPngIUBMeta?.name ?? "index-conversation",
    path: conversationMZZaPngIUBMeta?.path ?? "conversation",
    meta: conversationMZZaPngIUBMeta || {},
    alias: conversationMZZaPngIUBMeta?.alias || [],
    redirect: conversationMZZaPngIUBMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/conversation.vue").then(m => m.default || m)
  },
  {
    name: customerCenterbKAhlVieHdMeta?.name ?? "index-customerCenter",
    path: customerCenterbKAhlVieHdMeta?.path ?? "customerCenter",
    meta: customerCenterbKAhlVieHdMeta || {},
    alias: customerCenterbKAhlVieHdMeta?.alias || [],
    redirect: customerCenterbKAhlVieHdMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/customerCenter.vue").then(m => m.default || m)
  },
  {
    name: _403m3qOfsvhuqMeta?.name ?? "index-errorPage-403",
    path: _403m3qOfsvhuqMeta?.path ?? "errorPage/403",
    meta: _403m3qOfsvhuqMeta || {},
    alias: _403m3qOfsvhuqMeta?.alias || [],
    redirect: _403m3qOfsvhuqMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/errorPage/403.vue").then(m => m.default || m)
  },
  {
    name: _404I77BEvb8V2Meta?.name ?? "index-errorPage-404",
    path: _404I77BEvb8V2Meta?.path ?? "errorPage/404",
    meta: _404I77BEvb8V2Meta || {},
    alias: _404I77BEvb8V2Meta?.alias || [],
    redirect: _404I77BEvb8V2Meta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/errorPage/404.vue").then(m => m.default || m)
  },
  {
    name: feedbackcscxJJTwtiMeta?.name ?? "index-feedback",
    path: feedbackcscxJJTwtiMeta?.path ?? "feedback",
    meta: feedbackcscxJJTwtiMeta || {},
    alias: feedbackcscxJJTwtiMeta?.alias || [],
    redirect: feedbackcscxJJTwtiMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/feedback.vue").then(m => m.default || m)
  },
  {
    name: historyN2wHI1j50PMeta?.name ?? "index-history",
    path: historyN2wHI1j50PMeta?.path ?? "history",
    meta: historyN2wHI1j50PMeta || {},
    alias: historyN2wHI1j50PMeta?.alias || [],
    redirect: historyN2wHI1j50PMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/history.vue").then(m => m.default || m)
  },
  {
    name: homeeNvDUvt6suMeta?.name ?? "index-home",
    path: homeeNvDUvt6suMeta?.path ?? "home",
    meta: homeeNvDUvt6suMeta || {},
    alias: homeeNvDUvt6suMeta?.alias || [],
    redirect: homeeNvDUvt6suMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/home.vue").then(m => m.default || m)
  },
  {
    name: profilecGaZf1411mMeta?.name ?? "index-profile",
    path: profilecGaZf1411mMeta?.path ?? "profile",
    meta: profilecGaZf1411mMeta || {},
    alias: profilecGaZf1411mMeta?.alias || [],
    redirect: profilecGaZf1411mMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/profile.vue").then(m => m.default || m)
  },
  {
    name: setupG5cF1dYuouMeta?.name ?? "index-setup",
    path: setupG5cF1dYuouMeta?.path ?? "setup",
    children: [
  {
    name: assignSjro3xUqyzMeta?.name ?? "index-setup-assign",
    path: assignSjro3xUqyzMeta?.path ?? "assign",
    meta: assignSjro3xUqyzMeta || {},
    alias: assignSjro3xUqyzMeta?.alias || [],
    redirect: assignSjro3xUqyzMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/assign.vue").then(m => m.default || m)
  },
  {
    name: basicvYdQvMRViXMeta?.name ?? "index-setup-basic",
    path: basicvYdQvMRViXMeta?.path ?? "basic",
    meta: basicvYdQvMRViXMeta || {},
    alias: basicvYdQvMRViXMeta?.alias || [],
    redirect: basicvYdQvMRViXMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/basic.vue").then(m => m.default || m)
  },
  {
    name: blacklisthdG3UHxpjIMeta?.name ?? "index-setup-blacklist",
    path: blacklisthdG3UHxpjIMeta?.path ?? "blacklist",
    meta: blacklisthdG3UHxpjIMeta || {},
    alias: blacklisthdG3UHxpjIMeta?.alias || [],
    redirect: blacklisthdG3UHxpjIMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/blacklist.vue").then(m => m.default || m)
  },
  {
    name: conversationTagQ1CBcS01yAMeta?.name ?? "index-setup-conversationTag",
    path: conversationTagQ1CBcS01yAMeta?.path ?? "conversationTag",
    meta: conversationTagQ1CBcS01yAMeta || {},
    alias: conversationTagQ1CBcS01yAMeta?.alias || [],
    redirect: conversationTagQ1CBcS01yAMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/conversationTag.vue").then(m => m.default || m)
  },
  {
    name: customerTagmA1nvn7o77Meta?.name ?? "index-setup-customerTag",
    path: customerTagmA1nvn7o77Meta?.path ?? "customerTag",
    meta: customerTagmA1nvn7o77Meta || {},
    alias: customerTagmA1nvn7o77Meta?.alias || [],
    redirect: customerTagmA1nvn7o77Meta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/customerTag.vue").then(m => m.default || m)
  },
  {
    name: queueltOyyundh1Meta?.name ?? "index-setup-queue",
    path: queueltOyyundh1Meta?.path ?? "queue",
    meta: queueltOyyundh1Meta || {},
    alias: queueltOyyundh1Meta?.alias || [],
    redirect: queueltOyyundh1Meta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/queue.vue").then(m => m.default || m)
  },
  {
    name: quickReplyu4zwm5EYqGMeta?.name ?? "index-setup-quickReply",
    path: quickReplyu4zwm5EYqGMeta?.path ?? "quickReply",
    meta: quickReplyu4zwm5EYqGMeta || {},
    alias: quickReplyu4zwm5EYqGMeta?.alias || [],
    redirect: quickReplyu4zwm5EYqGMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/quickReply.vue").then(m => m.default || m)
  },
  {
    name: roles84da5NiSS7Meta?.name ?? "index-setup-roles",
    path: roles84da5NiSS7Meta?.path ?? "roles",
    meta: roles84da5NiSS7Meta || {},
    alias: roles84da5NiSS7Meta?.alias || [],
    redirect: roles84da5NiSS7Meta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/roles.vue").then(m => m.default || m)
  },
  {
    name: satisfactionVGGI0B2ZYiMeta?.name ?? "index-setup-satisfaction",
    path: satisfactionVGGI0B2ZYiMeta?.path ?? "satisfaction",
    meta: satisfactionVGGI0B2ZYiMeta || {},
    alias: satisfactionVGGI0B2ZYiMeta?.alias || [],
    redirect: satisfactionVGGI0B2ZYiMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/satisfaction.vue").then(m => m.default || m)
  },
  {
    name: sensitivegnklZAz8ziMeta?.name ?? "index-setup-sensitive",
    path: sensitivegnklZAz8ziMeta?.path ?? "sensitive",
    meta: sensitivegnklZAz8ziMeta || {},
    alias: sensitivegnklZAz8ziMeta?.alias || [],
    redirect: sensitivegnklZAz8ziMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/sensitive.vue").then(m => m.default || m)
  },
  {
    name: teamGwsWFfWzaXMeta?.name ?? "index-setup-team",
    path: teamGwsWFfWzaXMeta?.path ?? "team",
    meta: teamGwsWFfWzaXMeta || {},
    alias: teamGwsWFfWzaXMeta?.alias || [],
    redirect: teamGwsWFfWzaXMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/team.vue").then(m => m.default || m)
  },
  {
    name: trustedDomain0TM51LJkSlMeta?.name ?? "index-setup-trustedDomain",
    path: trustedDomain0TM51LJkSlMeta?.path ?? "trustedDomain",
    meta: trustedDomain0TM51LJkSlMeta || {},
    alias: trustedDomain0TM51LJkSlMeta?.alias || [],
    redirect: trustedDomain0TM51LJkSlMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/trustedDomain.vue").then(m => m.default || m)
  },
  {
    name: webAccessKvVmWmGBnLMeta?.name ?? "index-setup-webAccess",
    path: webAccessKvVmWmGBnLMeta?.path ?? "webAccess",
    children: [
  {
    name: setup2iZshKHPBeMeta?.name ?? "index-setup-webAccess-setup",
    path: setup2iZshKHPBeMeta?.path ?? "setup",
    meta: setup2iZshKHPBeMeta || {},
    alias: setup2iZshKHPBeMeta?.alias || [],
    redirect: setup2iZshKHPBeMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/webAccess/setup.vue").then(m => m.default || m)
  },
  {
    name: webmXcwSAndb9Meta?.name ?? "index-setup-webAccess-web",
    path: webmXcwSAndb9Meta?.path ?? "web",
    meta: webmXcwSAndb9Meta || {},
    alias: webmXcwSAndb9Meta?.alias || [],
    redirect: webmXcwSAndb9Meta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/webAccess/web.vue").then(m => m.default || m)
  }
],
    meta: webAccessKvVmWmGBnLMeta || {},
    alias: webAccessKvVmWmGBnLMeta?.alias || [],
    redirect: webAccessKvVmWmGBnLMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup/webAccess.vue").then(m => m.default || m)
  }
],
    meta: setupG5cF1dYuouMeta || {},
    alias: setupG5cF1dYuouMeta?.alias || [],
    redirect: setupG5cF1dYuouMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index/setup.vue").then(m => m.default || m)
  }
],
    meta: indexpNJODP33YvMeta || {},
    alias: indexpNJODP33YvMeta?.alias || [],
    redirect: indexpNJODP33YvMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inviteUserVerifyiqirooxJP2Meta?.name ?? "inviteUserVerify",
    path: inviteUserVerifyiqirooxJP2Meta?.path ?? "/inviteUserVerify",
    meta: inviteUserVerifyiqirooxJP2Meta || {},
    alias: inviteUserVerifyiqirooxJP2Meta?.alias || [],
    redirect: inviteUserVerifyiqirooxJP2Meta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/inviteUserVerify.vue").then(m => m.default || m)
  },
  {
    name: loginbUA1wJ5aBWMeta?.name ?? "login",
    path: loginbUA1wJ5aBWMeta?.path ?? "/login",
    meta: loginbUA1wJ5aBWMeta || {},
    alias: loginbUA1wJ5aBWMeta?.alias || [],
    redirect: loginbUA1wJ5aBWMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/login.vue").then(m => m.default || m)
  },
  {
    name: registermTzyMzL8MZMeta?.name ?? "register",
    path: registermTzyMzL8MZMeta?.path ?? "/register",
    meta: registermTzyMzL8MZMeta || {},
    alias: registermTzyMzL8MZMeta?.alias || [],
    redirect: registermTzyMzL8MZMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/register.vue").then(m => m.default || m)
  },
  {
    name: registerVerifyA942tluL9pMeta?.name ?? "registerVerify",
    path: registerVerifyA942tluL9pMeta?.path ?? "/registerVerify",
    meta: registerVerifyA942tluL9pMeta || {},
    alias: registerVerifyA942tluL9pMeta?.alias || [],
    redirect: registerVerifyA942tluL9pMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/registerVerify.vue").then(m => m.default || m)
  },
  {
    name: resetPwde9sDNq7BJfMeta?.name ?? "resetPwd",
    path: resetPwde9sDNq7BJfMeta?.path ?? "/resetPwd",
    meta: resetPwde9sDNq7BJfMeta || {},
    alias: resetPwde9sDNq7BJfMeta?.alias || [],
    redirect: resetPwde9sDNq7BJfMeta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/resetPwd.vue").then(m => m.default || m)
  },
  {
    name: verifyQK6svCR667Meta?.name ?? "verify",
    path: verifyQK6svCR667Meta?.path ?? "/verify",
    meta: verifyQK6svCR667Meta || {},
    alias: verifyQK6svCR667Meta?.alias || [],
    redirect: verifyQK6svCR667Meta?.redirect || undefined,
    component: () => import("C:/develop/workspace/aican_v2_ui/pages/verify.vue").then(m => m.default || m)
  }
]