export default defineNuxtPlugin(() => {
  const isLoading = useState('loading', () => true)

  return {
    provide: {
      startLoading: () => (isLoading.value = true),
      stopLoading: () => (isLoading.value = false)
    }
  }
})
