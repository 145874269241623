<template>
  <div v-if="isLoading" class="progressLoading">
    <v-progress-circular
      color="primary"
      indeterminate
    />
    <div class="mt-4">
      loading...
    </div>
  </div>
</template>

<script setup>
const isLoading = ref(true)

onMounted(() => {
  setTimeout(() => {
    isLoading.value = false
  }, 1000)
})
</script>

<style scoped>
.progressLoading {
  text-align:center;
  position: absolute;
  top: 50%; left: 50%; transform: translate(-50%, -50%);
}
</style>
