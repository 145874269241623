import { defineStore } from 'pinia'
import { getUsersListFn } from '@/api/auth/users'
import {
  getPermissionsFn,
  editPermissionsFn,
  editRoleFn,
  editRolePermissionsFn,
  getUserPermissionsFn
} from '@/api/auth/roles'

export const useRolesInfo = defineStore('rolesInfo', {
  state: () => ({
    progressLoading: false,
    getAgentLoading: false,
    agentList: [],
    allPermissions: [],
    checkedRolePermissionIds: [],
    permissionLoading: false,
    defaultAllPermission: [],

    routeMiddleware: [], // 路由守衛
    menusRouter: [], // all菜單權限
    setupMenuRouter: [], // 設置頁菜單權限
    userPermissions: [], // all權限(不包含menu)
    userAuths: []
  }),
  getters: {

  },
  actions: {
    // 獲取用戶列表
    async getAgentList (params, currentRoleId, join, joined) {
      const param = {
        pageNum: params.pageNum || 0,
        pageSize: params.pageSizeNum || 20
      }

      const res = await getUsersListFn(param)
      const data = res.data.value
      if (data.code === 200) {
        const result = data.data.data

        // 判斷加入或已加入
        result.forEach((agent, index) => {
          result[index].joinRole = {
            state: currentRoleId === agent.roleId,
            stateClass: currentRoleId === agent.roleId ? 'join' : 'noJoin',
            btnText: currentRoleId === agent.roleId ? joined : join
          }
        })

        // 排序
        const noJoinArray = result.filter(e => e.joinRole.state === false)
        const joinArray = result.filter(e => e.joinRole.state === true)
        const sortArray = noJoinArray.concat(joinArray)

        this.agentList = sortArray
        this.getAgentLoading = false
      }
    },
    // 獲取權限、編輯權限
    async getPermissions (param, type) {
      this.permissionLoading = true

      const params = {
        roleId: param.roleId
      }
      const res = type === 'add' ? await getPermissionsFn(param) : await editPermissionsFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this.checkedRolePermissionIds = data.data.checkedRolePermissionIds
        this.defaultAllPermission = data.data.allPermissions
        this.buildTree(data.data.allPermissions)

        this.permissionLoading = false
        return true
      }
    },
    buildTree (array) {
      const map = {}
      const tree = []

      // 先根據 id 建立對應的 map，方便查找
      array.forEach((item) => {
        item.label = item.text
        map[item.id] = { ...item }
      })

      // 根據 parentId 構建層級關係
      array.forEach((item) => {
        if (item.parentId === '0') {
          tree.push(map[item.id])
        } else if (map[item.parentId]) {
          if (!map[item.parentId].children) {
            map[item.parentId].children = []
          }
          map[item.parentId].children.push(map[item.id])
        }
      })

      this.allPermissions = tree
    },
    // 編輯角色
    async editRole (roleId, formData) {
      const params = {
        roleId
      }
      const res = await editRoleFn(params, formData)
      const data = res.data.value
      if (data.code === 200) {
        return true
      }
    },
    // 修改角色權限
    async editRolePermission (roleId, formData) {
      const params = {
        roleId
      }
      const res = await editRolePermissionsFn(params, formData)
      const data = res.data.value
      if (data.code === 200) {
        return true
      }
    },
    // 取得當前用戶的角色權限
    async getUserPermissions () {
      const res = await getUserPermissionsFn()
      const data = res.data.value
      if (data.code === 200) {
        const menuMap = {}
        const menus = []

        data.data.menus.forEach((item) => {
          menuMap[item.id] = item
        })

        data.data.menus.forEach((item) => {
          if (item.parentId === '0') {
            menus.push(item) // 根節點
          } else if (menuMap[item.parentId]) {
            if (!menuMap[item.parentId].children) {
              menuMap[item.parentId].children = []
            }
            // children
            menuMap[item.parentId].children.push(item)
          }
        })

        // 設置頁路由處理
        const setting = menus.filter(item => item.code === 'setting')
        if (setting.length > 0) {
          this.setupMenuRouter = setting[0].children

          setting[0].children.forEach((i) => {
            i.children?.forEach((item) => {
              item.url = '/setup' + item.url
            })
          })
        }

        this.routeMiddleware = data.data.menus

        this.userPermissions = []
        if (data.data.functions.length > 0) {
          data.data.functions.forEach(item => this.userPermissions.push(item.id))
        }

        this.menusRouter = menus

        return this.routeMiddleware
      }
    }
  }
})
