<!-- eslint-disable no-unused-vars -->
<template>
  <div id="app">
    <div v-if="isLoading" class="loading-container">
      <ProgressLoading />
    </div>
    <NuxtLayout v-else>
      <NuxtLoadingIndicator :throttle="1" />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
const isLoading = useState('loading')

onMounted(() => {
  setTimeout(() => {
    isLoading.value = false
  }, 1000)
})
</script>

<style scoped>
.loading-container {
  position: fixed; top: 0; left: 0; z-index: 9999;
  width: 100%; height: 100vh;
  background: white;
  display: flex; justify-content: center; align-items: center;
}
</style>
