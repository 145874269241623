import { useRolesInfo } from '@/stores/roles'

export default {
  mounted (el, binding) {
    const permissionRoles = binding.value

    if (Array.isArray(permissionRoles) && permissionRoles.length > 0) {
      const rolesInfo = useRolesInfo()
      const permissions = rolesInfo?.userPermissions || [] // 確保是陣列

      const hasPermission = permissionRoles.some(permissionId =>
        permissions.includes(permissionId) // 判断權限
      )

      if (!hasPermission) {
        el.parentNode?.removeChild(el) // 無權限則移除
      }
    } else {
      console.error('[v-permission] No roles set')
    }
  }
}
